<template>
  <div>
    <Header></Header>
    <PanelMenu></PanelMenu>

    <div class="panel-page">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="panel-table">
              <div class="head">
                <h3>Create Order</h3>
                <div class="d-flex">
                  <router-link to="/orders">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                      /></svg
                    >&nbsp; Go back
                  </router-link>
                </div>
              </div>
              <div class="content">
                <div
                  :class="
                    activeTab == 2 || activeTab == 3
                      ? 'order-steps step2'
                      : activeTab == 4 || activeTab == 5
                      ? 'order-steps step3'
                      : 'order-steps'
                  "
                >
                  <div
                    :class="
                      activeTab == 1 ||
                      activeTab == 2 ||
                      activeTab == 3 ||
                      activeTab == 4 ||
                      activeTab == 5
                        ? 'step-item active'
                        : 'step-item'
                    "
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-box-seam"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"
                        />
                      </svg>
                    </span>
                    Order Information
                  </div>
                  <div
                    :class="
                      activeTab == 2 || activeTab == 3 || activeTab == 4 || activeTab == 5
                        ? 'step-item active'
                        : 'step-item'
                    "
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-truck"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                        />
                      </svg>
                    </span>
                    Couriers & Services
                  </div>
                  <div
                    :class="
                      activeTab == 4 || activeTab == 5 ? 'step-item active' : 'step-item'
                    "
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-newspaper"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"
                        />
                        <path
                          d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"
                        />
                      </svg>
                    </span>
                    Order Overview
                  </div>
                </div>

                <div class="order-tab" v-if="activeTab == 1">
                  <div class="tab-cell">
                    <div class="row">
                      <div class="col-12">
                        <h4>Sender Address</h4>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Full Name</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].sender.fullname"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Phone (optional)</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].sender.phone"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Tax ID (optional)</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].sender.taxid"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="v-form-item">
                          <span>Country</span>
                          <country-select
                            v-model="orderData.shipments[0].sender.country"
                            :country="orderData.shipments[0].sender.country"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="v-form-item">
                          <span>Address Line 1</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].sender.line1"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="v-form-item">
                          <span>Address Line 2</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].sender.line2"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>City</span>
                          <region-select
                            :regionName="true"
                            v-model="orderData.shipments[0].sender.city"
                            :country="orderData.shipments[0].sender.country"
                            :region="orderData.shipments[0].sender.city"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>State</span>
                          <select
                            v-model="orderData.shipments[0].sender.state"
                            v-if="orderData.shipments[0].sender.country == 'US'"
                          >
                            <option
                              v-for="(state, i) in usStates"
                              :key="i"
                              :value="state.code"
                            >
                              {{ state.name }}
                            </option>
                          </select>
                          <select
                            v-model="orderData.shipments[0].sender.state"
                            v-if="orderData.shipments[0].sender.country == 'CA'"
                          >
                            <option
                              v-for="(state, i) in caStates"
                              :key="i"
                              :value="state.code"
                            >
                              {{ state.name }}
                            </option>
                          </select>
                          <input
                            type="text"
                            placeholder="State"
                            v-model="orderData.shipments[0].sender.state"
                            v-if="
                              orderData.shipments[0].sender.country != 'US' &&
                              orderData.shipments[0].sender.country != 'CA'
                            "
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Zipcode</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].sender.zip"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-cell">
                    <div class="row">
                      <div class="col-12">
                        <h4>Recipient Address</h4>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Full Name</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].recipient.fullname"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Phone (optional)</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].recipient.phone"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Tax ID (optional)</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].recipient.taxid"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="v-form-item">
                          <span>Country</span>
                          <country-select
                            v-model="orderData.shipments[0].recipient.country"
                            :country="orderData.shipments[0].recipient.country"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="v-form-item">
                          <span>Address Line 1</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].recipient.line1"
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="v-form-item">
                          <span>Address Line 2</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].recipient.line2"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>City</span>
                          <region-select
                            :regionName="true"
                            v-model="orderData.shipments[0].recipient.city"
                            :country="orderData.shipments[0].recipient.country"
                            :region="orderData.shipments[0].recipient.city"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <div class="v-form-item">
                            <span>State</span>
                            <select
                              v-model="orderData.shipments[0].recipient.state"
                              v-if="orderData.shipments[0].recipient.country == 'US'"
                            >
                              <option
                                v-for="(state, i) in usStates"
                                :key="i"
                                :value="state.code"
                              >
                                {{ state.name }}
                              </option>
                            </select>
                            <select
                              v-model="orderData.shipments[0].recipient.state"
                              v-if="orderData.shipments[0].recipient.country == 'CA'"
                            >
                              <option
                                v-for="(state, i) in caStates"
                                :key="i"
                                :value="state.code"
                              >
                                {{ state.name }}
                              </option>
                            </select>
                            <input
                              type="text"
                              placeholder="State"
                              v-model="orderData.shipments[0].recipient.state"
                              v-if="
                                orderData.shipments[0].recipient.country != 'US' &&
                                orderData.shipments[0].recipient.country != 'CA'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="v-form-item">
                          <span>Zipcode</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].recipient.zip"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-cell">
                    <div class="row">
                      <div class="col-12">
                        <h4>Package Dimensions</h4>
                      </div>
                      <div class="col-lg-3">
                        <div class="v-form-item">
                          <span>Length (inches)</span>
                          <input
                            type="number"
                            v-model="orderData.shipments[0].package.length"
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="v-form-item">
                          <span>Width (inches)</span>
                          <input
                            type="number"
                            v-model="orderData.shipments[0].package.width"
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="v-form-item">
                          <span>Height (inches)</span>
                          <input
                            type="number"
                            v-model="orderData.shipments[0].package.height"
                          />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="v-form-item">
                          <span>Weight (pounds)</span>
                          <input
                            type="number"
                            v-model="orderData.shipments[0].package.weight"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center mt-5">
                    <button class="main-btn" @click="getAvailableRates()">
                      <b-spinner
                        variant="light"
                        class="mr-2"
                        v-if="isLoadingBtn"
                        small
                      ></b-spinner>
                      Continue
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-right"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <div class="order-tab" v-if="activeTab == 2">
                  <div class="services-list">
                    <div class="services-list-head">
                      <span>Couier</span>
                      <span>Max Weight</span>
                      <span>Service Options</span>
                      <span>Pricing</span>
                      <span></span>
                    </div>
                    <div class="services-list-body">
                      <div class="service-item" v-for="(rate, i) in rates" :key="i">
                        <div class="cell">
                          <div class="name">
                            <div class="logo">
                              <img src="../../assets/USPS.png" alt="" />
                            </div>
                            <div>
                              <strong>{{ rate.service.name }}</strong>
                              <small>{{ rate.service.description }}</small>
                            </div>
                          </div>
                        </div>
                        <div class="cell">
                          <span>{{ rate.service.max_weight }} pounds</span>
                        </div>
                        <div class="cell">
                          <span
                            >Insurance
                            <div
                              v-if="rate.service.properties.insurance_available"
                              class="badge badge-success px-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-check-circle"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path
                                  d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                                />
                              </svg>
                            </div>
                            <div v-else class="badge badge-danger px-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-x-circle"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path
                                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                />
                              </svg>
                            </div>
                          </span>
                          <span
                            >Signature
                            <div
                              v-if="rate.service.properties.signature_available"
                              class="badge badge-success px-2"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-check-circle"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path
                                  d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                                />
                              </svg>
                            </div>
                            <div v-else class="badge badge-danger px-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-x-circle"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                                />
                                <path
                                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                                />
                              </svg></div
                          ></span>
                        </div>
                        <div class="cell">
                          <div class="price">{{ rate.price | toCurrency }}</div>
                        </div>
                        <div class="cell">
                          <button @click="selectRate(rate)">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-truck"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M0 3.5A1.5 1.5 0 0 1 1.5 2h9A1.5 1.5 0 0 1 12 3.5V5h1.02a1.5 1.5 0 0 1 1.17.563l1.481 1.85a1.5 1.5 0 0 1 .329.938V10.5a1.5 1.5 0 0 1-1.5 1.5H14a2 2 0 1 1-4 0H5a2 2 0 1 1-3.998-.085A1.5 1.5 0 0 1 0 10.5v-7zm1.294 7.456A1.999 1.999 0 0 1 4.732 11h5.536a2.01 2.01 0 0 1 .732-.732V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .294.456zM12 10a2 2 0 0 1 1.732 1h.768a.5.5 0 0 0 .5-.5V8.35a.5.5 0 0 0-.11-.312l-1.48-1.85A.5.5 0 0 0 13.02 6H12v4zm-9 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm9 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                              />
                            </svg>
                            SHIP
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="order-tab" v-if="activeTab == 3">
                  <div class="properties-list">
                    <div class="mb-3 d-flex justify-content-between align-items-center">
                      <h4 class="text-white">Properties</h4>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="v-form-item">
                          <span>Insurance</span>
                          <input
                            type="text"
                            v-model="orderData.shipments[0].properties.insurance"
                          />
                          <small
                            >Carriers provide 100 USD insurance by default for
                            free.</small
                          >
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="v-form-item">
                          <span>Signature</span>
                          <select v-model="orderData.shipments[0].properties.signature">
                            <option selected disabled>Choose type</option>
                            <option value="no_signature">No Signature</option>
                            <option value="signature_required">Signature Required</option>
                            <option value="adult_signature_required">
                              Adult Signature Required
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="orderData.shipments[0].recipient.country != 'US'">
                    <div class="mb-3 d-flex justify-content-between align-items-center">
                      <h4 class="text-white">Customs</h4>
                      <button class="btn text-white" @click="addNewCustom()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path
                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          />
                          <path
                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                          /></svg
                        >&nbsp;&nbsp;Add New
                      </button>
                    </div>

                    <div
                      class="customs-row"
                      v-for="(
                        custom, i
                      ) in orderData.shipments[0].customs.slice().reverse()"
                      :key="i"
                    >
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="v-form-item mb-0">
                            <span>HS Code</span>
                            <select v-model="custom.hs_code">
                              <option
                                v-for="(hs_code, i) in hsCodes"
                                :key="i"
                                :value="hs_code.code"
                              >
                                {{ hs_code.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="v-form-item mb-0">
                            <span>Quantity</span>
                            <input type="number" v-model="custom.quantity" />
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="v-form-item mb-0">
                            <span>Declared Value</span>
                            <input type="text" v-model="custom.declared_value" />
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="v-form-item mb-0">
                            <span>Description</span>
                            <input v-model="custom.description" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <button class="main-btn mt-3" @click="viewLabel()">
                    Continue
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </button>
                </div>

                <div class="order-tab" v-if="activeTab == 4">
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="view-card">
                        <strong>Package and Service</strong>
                        <ul>
                          <li>
                            <b>Dimensions:</b>
                            {{ finalLabelData.shipments[0].package.width }}x{{
                              finalLabelData.shipments[0].package.length
                            }}x{{ finalLabelData.shipments[0].package.height }}
                          </li>
                          <li>
                            <b>Weight:</b>
                            {{ finalLabelData.shipments[0].package.weight }} lbs
                          </li>
                          <li>
                            <b>Service:</b> {{ finalLabelData.shipments[0].service }}
                          </li>
                          <li>
                            <b>Insurance:</b>
                            {{
                              finalLabelData.shipments[0].properties.insurance
                                | toCurrency
                            }}
                          </li>
                        </ul>
                      </div>
                      <div class="card-alert mt-3">
                        Please verify the informations is correct before proceeding. Label
                        details cannot be changed once created.
                      </div>
                      <div class="card-alert mt-3">
                        You can only print labels in 24 hours after creation.
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="view-card">
                        <strong>Sender Address</strong>
                        <ul>
                          <li>
                            <b>Full name:</b>
                            {{ finalLabelData.shipments[0].sender.fullname }}
                          </li>
                          <li>
                            <b>Address Line 1:</b>
                            {{ finalLabelData.shipments[0].sender.line1 }}
                          </li>
                          <li>
                            <b>Address Line 2:</b>
                            {{ finalLabelData.shipments[0].sender.line2 }}
                          </li>
                          <li>
                            <b>City:</b> {{ finalLabelData.shipments[0].sender.city }}
                          </li>
                          <li>
                            <b>State:</b> {{ finalLabelData.shipments[0].sender.state }}
                          </li>
                          <li>
                            <b>Zip:</b> {{ finalLabelData.shipments[0].sender.zip }}
                          </li>
                          <li>
                            <b>Country:</b>
                            {{ finalLabelData.shipments[0].sender.country }}
                          </li>
                          <li>
                            <b>Tax ID:</b>
                            {{
                              finalLabelData.shipments[0].sender.taxid
                                ? finalLabelData.shipments[0].sender.taxid
                                : "N/A"
                            }}
                          </li>
                          <li>
                            <b>Phone:</b>
                            {{
                              finalLabelData.shipments[0].sender.phone
                                ? finalLabelData.shipments[0].sender.phone
                                : "N/A"
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="view-card">
                        <strong>Recipient Address</strong>
                        <ul>
                          <li>
                            <b>Full name:</b>
                            {{ finalLabelData.shipments[0].recipient.fullname }}
                          </li>
                          <li>
                            <b>Address Line 1:</b>
                            {{ finalLabelData.shipments[0].recipient.line1 }}
                          </li>
                          <li>
                            <b>Address Line 2:</b>
                            {{ finalLabelData.shipments[0].recipient.line2 }}
                          </li>
                          <li>
                            <b>City:</b> {{ finalLabelData.shipments[0].recipient.city }}
                          </li>
                          <li>
                            <b>State:</b>
                            {{ finalLabelData.shipments[0].recipient.state }}
                          </li>
                          <li>
                            <b>Zip:</b> {{ finalLabelData.shipments[0].recipient.zip }}
                          </li>
                          <li>
                            <b>Country:</b>
                            {{ finalLabelData.shipments[0].recipient.country }}
                          </li>
                          <li>
                            <b>Tax ID:</b>
                            {{
                              finalLabelData.shipments[0].recipient.taxid
                                ? finalLabelData.shipments[0].recipient.taxid
                                : "N/A"
                            }}
                          </li>
                          <li>
                            <b>Phone:</b>
                            {{
                              finalLabelData.shipments[0].recipient.phone
                                ? finalLabelData.shipments[0].recipient.phone
                                : "N/A"
                            }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-12 mt-3 text-right">
                      <button
                        class="btn btn-primary btn-lg btn-block"
                        @click="purchaseOrder()"
                      >
                        Create Label
                      </button>
                    </div>
                  </div>
                </div>

                <div class="order-tab" v-if="activeTab == 5">
                  <div class="verify-alert">
                    <div class="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check-circle"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                        />
                        <path
                          d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                        />
                      </svg>
                    </div>
                    <strong>Purchase has been created for processing</strong>
                    <router-link class="btn btn-primary btn-lg mt-3" to="/orders"
                      >View All Orders</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { BAlert, BSpinner } from "bootstrap-vue";
import axios from "axios";
import Header from "../../layouts/components/HeaderComp.vue";
import Footer from "../../layouts/components/FooterComp.vue";
import PanelMenu from "../../layouts/components/PanelMenu.vue";
import { uuid } from "vue-uuid";
import usStates from "../../utils/usa-states";
import caStates from "../../utils/ca-states";
import hsCodes from "../../utils/hs-codes";
export default {
  components: {
    BAlert,
    BSpinner,
    Header,
    Footer,
    PanelMenu,
  },
  data() {
    return {
      errors: [],
      showModal: false,
      isLoading: false,
      processStatus: 25,
      couriers: [],
      isLoadingBtn: false,

      finalLabelData: {},

      activeTab: 1,

      usStates: usStates,
      caStates: caStates,
      hsCodes: hsCodes,

      rates: [],

      selectedRate: {},

      orderData: {
        courier: "",
        default_service: "",
        max_price_per_shipment: 0,
        nonce: "",
        shipments: [
          {
            service: "",
            sender: {
              fullname: "",
              line1: "",
              line2: "",
              city: "",
              state: "",
              zip: "",
              country: "",
              phone: "",
              company: "",
              taxid: "",
            },
            recipient: {
              fullname: "",
              line1: "",
              line2: "",
              city: "",
              state: "",
              zip: "",
              country: "",
              phone: "",
              company: "",
              taxid: "",
            },
            package: {
              width: "",
              length: "",
              height: "",
              weight: "",
            },
            customs: [
              {
                hs_code: "",
                quantity: 0,
                declared_value: "",
                description: "",
              },
            ],
            properties: {},
          },
        ],
      },
    };
  },
  mounted() {
    this.orderData.nonce = uuid.v4();
  },
  methods: {
    getCouriers() {
      axios
        .get(`shipping/get-couriers`)
        .then((res) => {
          console.log(res.data.records);
          this.couriers = res.data.records;
        })
        .catch((err) => {
          this.errors.push(err);
        });
    },

    getAvailableRates() {
      this.isLoadingBtn = true;
      const shipmentData = {
        shipment: {
          sender: {
            ...this.orderData.shipments[0].sender,
          },
          recipient: {
            ...this.orderData.shipments[0].recipient,
          },
          package: {
            width: Number(this.orderData.shipments[0].package.width),
            length: Number(this.orderData.shipments[0].package.length),
            height: Number(this.orderData.shipments[0].package.height),
            weight: Number(this.orderData.shipments[0].package.weight),
          },
          customs: {},
          properties: {},
        },
      };

      axios
        .post(`shipping/get-rates`, shipmentData)
        .then((res) => {
          console.log(res.data);
          this.rates = res.data.rates;
          this.isLoadingBtn = false;
          this.activeTab = 2;
        })
        .catch((err) => {
          this.errors.push(err);
          if (err.response.data.code == "NO_RATES") {
            this.$toast.open({
              message: err.response.data.message,
              type: "error",
              position: "top-right",
            });
          } else {
          }
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
          this.isLoadingBtn = false;
        });
    },

    selectRate(rate) {
      this.selectedRate = rate;
      console.log(this.selectedRate);
      this.activeTab = 3;
    },

    addNewCustom() {
      this.orderData.shipments[0].customs.push({
        hs_code: "",
        quantity: 0,
        declared_value: "",
        description: "",
      });
    },

    viewLabel() {
      this.orderData.shipments[0].service = this.selectedRate.service.code;

      if (this.orderData.shipments[0].recipient.country == "US") {
        this.orderData.shipments[0].customs = {};
      }

      var shipments = this.orderData.shipments;

      shipments[0].customs[0].quantity = Number(
        this.orderData.shipments[0].customs[0].quantity
      );
      shipments[0].customs[0].declared_value = Number(
        this.orderData.shipments[0].customs[0].declared_value
      );

      (shipments[0].package.width = Number(this.orderData.shipments[0].package.width)),
        (shipments[0].package.length = Number(
          this.orderData.shipments[0].package.length
        )),
        (shipments[0].package.height = Number(
          this.orderData.shipments[0].package.height
        )),
        (shipments[0].package.weight = Number(
          this.orderData.shipments[0].package.weight
        )),
        (shipments[0].properties.insurance = Number(
          this.orderData.shipments[0].properties.insurance
        ));

      const labelData = {
        courier: this.selectedRate.courier.code,
        default_service: this.selectedRate.courier.code,
        max_price_per_shipment: null,
        nonce: this.orderData.nonce,
        shipments,
      };

      this.finalLabelData = labelData;

      this.activeTab = 4;

      console.log("LABEL DATA ", labelData);
    },

    purchaseOrder() {
      axios
        .post(`shipping/purchase`, this.finalLabelData)
        .then((res) => {
          console.log(res.data);
          if (res.data.code == "PURCHASE_CREATED") {
            this.activeTab = 5;
          }
        })
        .catch((err) => {
          this.errors.push(err);
          this.$toast.open({
            message: Object.values(err.response.data.message)[0],
            type: "error",
            position: "top-right",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
