module.exports = [
  {
    "code": "000012345",
    "name": "Accessories without battery",
  },
  {
    "code": "000012346",
    "name": "Accessories without",
  },
  {
    "code": "000012347",
    "name": "Without battery",
  },
  {
    "code": "000012348",
    "name": "Accessories battery",
  },
];